import React, { useEffect, useState } from "react";
import { useDeviceTypes } from "halifax";
import { RouteComponentProps } from "react-router-dom";
import { B2B_PORTAL_BANNER_CONTAINER_ID, RewardsAccount } from "redmond";

import {
  HIDDEN_BANNER_UNPROTECTED_PATHS,
  HIDDEN_HEADER_PATHS_DESKTOP,
  PREMIER_COLLECTION_BANNER_STYLE_PATHS,
  PRICE_MATCH_BANNER_PATHS,
} from "../../utils/urlPaths";
import "./styles.scss";
import clsx from "clsx";
import { Box, Slide, useScrollTrigger } from "@material-ui/core";
import { CapOneHeaderHomePageRightSection } from "../Header/components/DesktopHeader";
import { useUserContext } from "@capone/common";
import {
  getExperimentVariantCustomVariants,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS,
  CONTROL,
  useExperiments,
  CUSTOMER_PROFILE_EXPERIMENT,
  CUSTOMER_PROFILE_VARIANTS,
} from "../../context/experiments";
import fetchUserPreferencesStatus, {
  UserPreferencesStatusPayload,
} from "../../api/v1/user/fetchUserPreferencesStatus";

export interface IRootBannerProps extends RouteComponentProps {
  headerDisplayed?: boolean;
  isHidden?: boolean;
  canViewConsumer?: boolean;
  hideOnScroll?: boolean;
  rewardsAccounts: RewardsAccount[];
}

const HideOnScroll = ({ children }: { children: JSX.Element }) => {
  const trigger = useScrollTrigger({ disableHysteresis: true });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

export const RootBanner = (props: IRootBannerProps) => {
  const { headerDisplayed, isHidden, rewardsAccounts, ...browserRouterProps } =
    props;
  const { history } = browserRouterProps;
  const { matchesMobile } = useDeviceTypes();
  const [renderRightNav, setRenderRightNav] = useState(true);
  const [userPreferencesStatus, setUserPreferencesStatus] =
    useState<UserPreferencesStatusPayload>();

  const { isBusinessEligible } = useUserContext("capone");
  const expState = useExperiments();

  const LCForNonPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const isLCForNonPremiumCardHoldersEnabled =
    LCForNonPremiumCardholderVariant !== CONTROL;

  const isCustomerProfileExperiment =
    getExperimentVariantCustomVariants(
      expState.experiments,
      CUSTOMER_PROFILE_EXPERIMENT,
      CUSTOMER_PROFILE_VARIANTS
    ) !== CONTROL;

  useEffect(() => {
    const fetchPreferencesStatus = async () => {
      const prefsStatus = await fetchUserPreferencesStatus();
      setUserPreferencesStatus(prefsStatus);
    };
    if (isCustomerProfileExperiment) {
      fetchPreferencesStatus();
    }
  }, [isCustomerProfileExperiment]);

  useEffect(() => {
    if (!matchesMobile) {
      if (
        HIDDEN_HEADER_PATHS_DESKTOP.includes(history.location.pathname) ||
        isHidden // if banner is hidden (such as when its not authenticated, we should hide right nav as well)
      ) {
        setRenderRightNav(false);
      } else {
        setRenderRightNav(true);
      }
    }
  }, [history.location.pathname, matchesMobile]);

  const content = (
    <Box
      className={clsx("b2b-portal-banner-root", {
        "includes-right-section":
          renderRightNav && !matchesMobile && !isBusinessEligible,
        mobile: matchesMobile,
        "checkout-banner": PRICE_MATCH_BANNER_PATHS.includes(
          history.location.pathname
        ),
        isHidden,
        "pc-banner":
          PREMIER_COLLECTION_BANNER_STYLE_PATHS.includes(
            history.location.pathname
          ) && !isLCForNonPremiumCardHoldersEnabled,
        "includes-right-section-corporate":
          renderRightNav && !matchesMobile && isBusinessEligible,
      })}
    >
      <div
        id={B2B_PORTAL_BANNER_CONTAINER_ID}
        className={clsx({
          mobile: matchesMobile,
          isHidden: HIDDEN_BANNER_UNPROTECTED_PATHS.includes(
            history.location.pathname
          ),
        })}
      />

      {renderRightNav && !matchesMobile && (
        <CapOneHeaderHomePageRightSection
          showCustomerProfileNewBadge={
            userPreferencesStatus?.hasUsedPreferences === false
          }
          displayOnlyIcon
          {...props}
        />
      )}
    </Box>
  );

  return props.hideOnScroll ? <HideOnScroll>{content}</HideOnScroll> : content;
};
